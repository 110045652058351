<script lang="ts">
import type { ModalDataComponent } from '@components/Modals/types'

export let data: ModalDataComponent
const { content, props } = data
</script>

<div class="modal-body">
    <svelte:component this={content} {...props} />
</div>
