<script lang="ts">
import { onMount, onDestroy, createEventDispatcher } from 'svelte';
import type { ModalDataActionList } from '@components/Modals/types';
const dispatch = createEventDispatcher()

export let data: ModalDataActionList
const { content, actions } = data

function close() {
    dispatch('closemodal')
}
</script>

<div class="modal-body">

    {#if content}
        <div class="content mb-4">
            {@html content}
        </div>
    {/if}

    {#if actions && actions.length}
        <div class="list-group">
            {#each actions as action}
                <a
                    class={`list-group-item list-group-item-action list-group-item-${action.style || 'normal'}`}
                    href={`#action-${action.name}`}
                    data-action={action.name}
                    on:click={(e) => action.fn({ close }, e)}
                >
                    {action.text}
                </a>
            {/each}
        </div>
    {/if}
</div>
