<script lang="ts">
import { onMount, onDestroy } from 'svelte';
import type { ModalDataElementContent } from '@components/Modals/types'

export let data: ModalDataElementContent

let contentElement
let originalParent

onMount(() => {
    originalParent = data.content.parentNode
    contentElement.appendChild(data.content)
})

onDestroy(() => {
    if (originalParent) {
        originalParent.appendChild(data.content)
    }
})
</script>

<div bind:this={contentElement} class="modal-content">
</div>
