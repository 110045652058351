<script lang="ts">
import type { ModalDataHtmlContent } from '@components/Modals/types'

export let data: ModalDataHtmlContent

const { content } = data
</script>

<div class="modal-body">
    {@html content}
</div>
